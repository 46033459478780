
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnDestroy, OnInit, Renderer2, ChangeDetectorRef, HostListener, AfterViewInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState, getIsDeviceReady, getOnlineStatus, getUserProfile } from "./reducers/index";
import { DeviceReady, SetOnlineStatus, SetAvailableApps, WindowFocusStatus, SetActiveProfile, SetRightSidebarStatus } from "./actions/app";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "./common/providers/config.service";
import { AppConstants } from "./common/utils/app-constants";
import { TasksConstants } from "./task/shared/task-constacts";
import { ActivatedRoute, Router } from "@angular/router";
import { TaskService } from "./task/task.service";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { MessageTranslatorService } from "./task/services";
import { environment } from "../environments/environment";
import { ChangeServerDialogComponent } from "./task/components/change-server-dialog/change-server-dialog.component";
import { MatIconRegistry } from "@angular/material/icon";
import { takeWhile, map, filter, take, concatMap } from "rxjs/operators";
import { Observable, forkJoin, fromEvent, merge, of } from "rxjs";
import { ElectronService } from "./task/services/electron.service";
import { CommonUtil } from "./common/utils/common.utils";
import { TaskComposeComponent } from "./task/components/task-compose/task-compose.component";
import { MatDialog } from "@angular/material/dialog";
import { VNCActionWheelMenuService, VncLibraryService } from "vnc-library";
import { getAuthUser, SetEditTaskView, TasksRootState } from "../app/task/store";
import { TaskRepository } from "../app/task/repository/task.repository";
import { ResponsiveService } from "./common/providers/responsive.service";
import { DatabaseService } from "./task/services/database.service";

@Component({
  selector: "vp-app",
  template: `
    <router-outlet></router-outlet>
    <dialog-outlet></dialog-outlet>
    <vnc-action-wheel-menu
    *ngIf="!isMobileDevice"
    middleLabel="{{ 'PROFILE_INFO' | translate }}"
    label1="{{ 'START_AUDIO_CALL' | translate }}"
    label2="{{ 'START_CHAT' | translate }}"
    label3="{{ 'START_VIDEO_CALL' | translate }}"
    label4="{{ 'COMPOSE_MAIL' | translate }}"
    label5="{{ 'CREATE_TICKET' | translate }}"
    label6="{{ 'CREATE_TASK' | translate }}"
    (actionClicked)="handleActionClick($event)"
    style="display: none"></vnc-action-wheel-menu>
  `
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  isAlive: boolean = true;
  isOnline: boolean = false;
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  user: any = {};
  isMobileDevice = CommonUtil.isOnMobileDevice();
  constructor(
    private rootStore: Store<RootState>,
    private changeDetectionRef: ChangeDetectorRef,
    private translate: TranslateService,
    public configService: ConfigService,
    private router: Router,
    private taskService: TaskService,
    private broadcaster: Broadcaster,
    private messageTranslatorService: MessageTranslatorService,
    private matIconRegistry: MatIconRegistry,
    private electronService: ElectronService,
    private matDialog: MatDialog,
    private actionWheelMenuService: VNCActionWheelMenuService,
    private taskRepository: TaskRepository,
    private store: Store<TasksRootState>,
    private responsiveService: ResponsiveService,
    private databaseService: DatabaseService,
    private vncLibaryService: VncLibraryService,
  ) {
    this.matIconRegistry.registerFontClassAlias("mdi");
    let browserLang = this.translate.getBrowserLang();
    let localLang = localStorage.getItem(AppConstants.TASK_LANGUAGE);
    if (localLang !== null && localLang !== "undefined") {
      browserLang = localLang;
    }
    let selectedLanguage = browserLang.match(/en|de/) ? browserLang : "en";
    this.translate.use(selectedLanguage);
    this.translate.reloadLang(selectedLanguage);
    this.configService.language = selectedLanguage;
    this.checkInternetConnection();
    this.checkWindowFocused();

    this.broadcaster.on<boolean>("OFFLINE_CONNECTION")
    .subscribe(data => {
      const message = this.messageTranslatorService.getMessage(TasksConstants.OFFLINE);
      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
      });
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnInit() {
    this.actionWheelMenuService.getData().subscribe(v => {
      console.log("[AppComponent] actionWheelMenuService", v);
    });
    document.addEventListener("deviceready", this.onDeviceReady.bind(this));

    this.rootStore.select(getIsDeviceReady).pipe(filter(isReady => isReady), take(1)).subscribe( isReady => {
      if (typeof navigator !== "undefined" && navigator.splashscreen) {
        navigator.splashscreen.hide();
        this.changeDetectionRef.detectChanges();
      }
      if (typeof Keyboard !== "undefined") {
        Keyboard.shrinkView(true);
      }

      if (CommonUtil.isOnMobileDevice() && !CommonUtil.isOnIpad() && screen) {
        screen.orientation.lock("portrait");
      } else {
        screen.orientation.unlock();
      }

      if (environment.isCordova && StatusBar) {
        console.log("[AppComponent][onDeviceReady] StatusBar.show");
        StatusBar.show();
        if (CommonUtil.isOnIOS()) {
          StatusBar.backgroundColorByHexString("#0070BC");
          StatusBar.styleLightContent();
        }
      }

      if (universalLinks) {
        universalLinks.subscribe("launchedAppFromLink", this.onApplicationDidLaunchFromLink.bind(this));
        universalLinks.subscribe("triggerActionEvent", this.processTriggerActionEvent.bind(this));
      }

      document.addEventListener("online", this.handleConnectionChange.bind(this), false);
      document.addEventListener("offline", this.handleConnectionChange.bind(this), false);

      const initSuccess = () => {
        console.log("[openwith] init success!");
      };
      const initError = (err) => {
        console.log("[openwith] init failed: " + err);
      };
      cordova.openwith.init(initSuccess, initError);
      const myHandler = (intent) => {
        console.log("[openwith] intent received", intent);
        localStorage.setItem("shared_data", JSON.stringify({
          intent: intent
        }));
        this.handleSharing();
      };
      cordova.openwith.addHandler(myHandler);
    });
    this.responsiveService.screen$
      .pipe(takeWhile(() => this.isAlive))
      .subscribe(screen => {
        this.isMobileDevice = CommonUtil.isOnMobileDevice();
        this.changeDetectionRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.rootStore.select(getOnlineStatus).pipe(takeWhile(() => this.isAlive)).subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (!navigator.onLine) {
        let message = "";
        if (localStorage.getItem(AppConstants.TASK_LANGUAGE) === "de") {
          message = "Verbindung zum Internet unterbrochen";
        } else {
          message = "You lost internet connection";
        }
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
      }
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getUserProfile).pipe(takeWhile(() => this.isAlive)).subscribe(user => {
      if (user) {
        this.user = user;
        setTimeout( () => { this.changeDetectionRef.markForCheck(); }, 100);
      }
    });
  }

  private checkInternetConnection(): void {
    if (!environment.isCordova) {
      window.addEventListener("online", this.handleConnectionChange.bind(this));
      window.addEventListener("offline", this.handleConnectionChange.bind(this));
    } else {
      this.rootStore.dispatch(new SetOnlineStatus(navigator.onLine));
    }
  }

  private checkWindowFocused() {
    merge(
      fromEvent(window , "focus").pipe(map(() => true)) ,
      fromEvent(window , "blur").pipe(map(() => false)))
      .subscribe((focused) => {
        this.rootStore.dispatch(new WindowFocusStatus(focused));
    });
  }

  private handleConnectionChange(event): void {
    console.log("NETWORK connection status is now: ", event);
    if (environment.isElectron) {
      this.rootStore.select(getOnlineStatus).pipe(take(1)).subscribe(isOnline => {
        if (isOnline !== navigator.onLine) {
          setTimeout(() => {
            this.rootStore.dispatch(new SetOnlineStatus(navigator.onLine));
          }, 5000);
        } else {
          this.rootStore.dispatch(new SetOnlineStatus(navigator.onLine));
        }
      });
    } else {
      this.rootStore.dispatch(new SetOnlineStatus(navigator.onLine));
    }
    this.databaseService.getPendingOperationTask().subscribe(pendingTask => {
      if (pendingTask?.length && navigator.onLine) {
        pendingTask.sort((a, b) => a.position - b.position);
        this.processPendingTask(pendingTask);
      }
    })
  }

  async processPendingTask(pendingTasks) {
    console.log(" => Start: ", pendingTasks);
    let operationOnDuplicate = {};
    for (let task of pendingTasks) {
      if (task?.body?.operationType == "duplicateTask") {
        let count = 0;
        for (const t of task.body.tasks) {
          const taskComplete = await this.taskRepository.pendingDuplicateTasks([t]);
          if (taskComplete?.length) {
            this.databaseService.deleteBulkTasks(task.id,"NewTaskStore");
            this.databaseService.deleteBulkTasks(task.id, "OpenTaskStore");
            this.databaseService.deleteBulkTasks(task.id, "AllTaskStore");
            this.databaseService.addBulkTasks(taskComplete, "NewTaskStore");
            this.databaseService.addBulkTasks(taskComplete, "OpenTaskStore");
            this.databaseService.addBulkTasks(taskComplete, "AllTaskStore");
            operationOnDuplicate[task.id + count] = taskComplete[0].id;
            count++;
          }
        }
        this.databaseService.deletePendingOperations(task.id)
      }
      else if (["Priority", "AssignedTo", "StartDate", "DueDate", "Location", "List", "Tags", "Watchers"].includes(task?.body?.operationType)) {
        let taskToUpdate;
        if (operationOnDuplicate) {
          taskToUpdate = await task?.body?.tasks.map(t => {
            if (Object.keys(operationOnDuplicate).includes(t.id.toString())) {
              t.id = operationOnDuplicate[t.id];
            }
            return t;
          })
        }
        else taskToUpdate = task?.body?.tasks;
        const taskComplete = await this.taskRepository.updatePendingBulkTasks(taskToUpdate, task?.body?.fieldToUpdate, task?.body?.valueToUpdate)
        this.databaseService.deletePendingOperations(task.id)
        let taskIds = task?.body?.tasks.map(t => t.id);
        for (const t of taskIds ){
          await this.taskRepository.refreshUpdateTask(t);
        }
      }

      else if (task?.body?.operationType == "Recurring") {
        for (const t of task.body.tasks) {
          if (operationOnDuplicate) {
            if (Object.keys(operationOnDuplicate).includes(t.id.toString())) {
              t.id = operationOnDuplicate[t.id];
            }
          }
          const taskComplete = await this.taskRepository.updateBulkTasksPendingRecurring([t], task?.body?.fieldToUpdate, task?.body?.valueToUpdate)
        }
        this.databaseService.deletePendingOperations(task.id);
      }

      else if (task?.body?.operationType == "nonBulkUpdate") {
        const taskComplete = await this.taskRepository.updatePendingTask(task.body.oldTask, task.body.newTask, task.body.fileUploads, task.body.watcherMembers)
        this.databaseService.deletePendingOperations(task.id)
      }

      else if (task?.body?.operationType == "deleteTask") {
        for (let t of task.body.tasks) {
          if (operationOnDuplicate) {
            if (Object.keys(operationOnDuplicate).includes(t.toString())) {
              t = operationOnDuplicate[t];
            }
          }
          const taskComplete = await this.taskRepository.removePendingTasksQuery([t]);
        }
        this.databaseService.deletePendingOperations(task.id);
      }

      else if (task?.body?.operationType == "bulkUpdate") {
        for (const t of task.body.tasks) {
          if (operationOnDuplicate) {
            if (Object.keys(operationOnDuplicate).includes(t.id)) {
              t.id = operationOnDuplicate[t.id];
            }
          }
         const taskComplete = await this.taskRepository.updatePendingBulkTasks([t], task.body.status, task.body.args);
          if (taskComplete != "done") {
           for (const t of task.body.oldTasksBackUp ){
             this.databaseService.deleteBulkTasks(t.id, "CompletedTaskStore");
             if (t.status.name.toLowerCase() === "new") {
               this.databaseService.addBulkTasks([t], "NewTaskStore");
               this.databaseService.addBulkTasks([t], "OpenTaskStore");
             }
             else if (t.status.name.toLowerCase() === "open")
               this.databaseService.addBulkTasks([t], "OpenTaskStore");
           }
          }
        }
        this.databaseService.deletePendingOperations(task.id);
      }
    }
    this.store.dispatch(new SetEditTaskView(false));
    this.taskRepository.getTasks();
    this.taskRepository.getTasksStats();
    this.taskRepository.addAllTaskToIndexedDb();
  }

  onDeviceReady() {
    console.log("[App Component] Device Ready");
    this.rootStore.dispatch(new DeviceReady());

    if (window["cordova"].InAppBrowser) {
      window.open = window["cordova"].InAppBrowser.open;
    }

    document.addEventListener("backbutton", (e) => {
      if (document.querySelector("#registrationIframe") !== null) {
        document.querySelector("#registrationIframe").remove();
      } else if (document.querySelector("#forgotPasswordIframe") !== null) {
        document.querySelector("#forgotPasswordIframe").remove();
      } else if (document.querySelector("#loginIframe") !== null) {
        navigator.app.exitApp();
      } else if (document.querySelector("#tfaOtpIframe") !== null) {
        this.configService.hideTfaOtpIframe();
        this.configService.loginIframe();
      } else if (document.querySelector("vp-change-server-dialog") !== null) {
        this.broadcaster.broadcast("hideChangeServerDialog");
        console.log("[root.ts] onBacKButton", "hideChangeServerDialog");
      } else if (document.querySelector(".registration-activation") !== null) {
        this.broadcaster.broadcast("hideRegistrationActivationPage");
        console.log("[root.ts] onBacKButton", "hideRegistrationActivationPage");
      } else if (document.querySelector(".registration-form") !== null) {
        this.broadcaster.broadcast("hideRegistrationFormPage");
        console.log("[root.ts] onBacKButton", "hideRegistrationFormPage");
      } else if (document.querySelector(".forgotpassword-info") !== null) {
        this.broadcaster.broadcast("hideForgotPasswordInfoPage");
        console.log("[root.ts] onBacKButton", "hideForgotPasswordInfoPage");
      } else if (document.querySelector(".forgotpassword-form") !== null) {
        this.broadcaster.broadcast("hideForgotPasswordFormPage");
        console.log("[root.ts] onBacKButton", "hideForgotPasswordFormPage");
      } else if (document.querySelector(".passwordreset-form") !== null) {
        this.broadcaster.broadcast("hideResetPasswordFormPage");
        console.log("[root.ts] onBacKButton", "hideResetPasswordFormPage");
      } else if (document.querySelector(".vp-task-confirm-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskConfirmDialog");
        console.log("[root.ts] onBacKButton", "hideTaskConfirmDialog");
      } else if (document.querySelector(".vp-save-query-dialog") !== null) {
        this.broadcaster.broadcast("hideSaveQueryDialog");
        console.log("[root.ts] onBacKButton", "hideSaveQueryDialog");
      } else if (document.querySelector(".vp-description-diff-dialog") !== null) {
        this.broadcaster.broadcast("hideDiscriptionDiffDialog");
        console.log("[root.ts] onBacKButton", "hideDiscriptionDiffDialog");
      } else if (document.querySelector(".vp-task-watchers-dialog") !== null) {
        this.broadcaster.broadcast("hideWatcherDialog");
        console.log("[root.ts] onBacKButton", "hideWatcherDialog");
      } else if (document.querySelector(".vp-task-recording-voice-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskRecordVoiceMsgDialog");
        console.log("[root.ts] onBacKButton", "hideTaskRecordVoiceMsgDialog");
      } else if (document.querySelector(".vp-task-attachment-option-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAttachmentOptionDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAttachmentOptionDialog");
      } else if (document.querySelector(".vp-task-add-attachment-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAddAttachmentDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAddAttachmentDialog");
      } else if (document.querySelector(".vp-task-add-attachment-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAddAttachmentDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAddAttachmentDialog");
      } else if (document.querySelector(".vp-task-reminder-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAddReminderDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAddReminderDialog");
      } else if (document.querySelector(".vp-task-add-fields-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAddFieldDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAddFieldDialog");
      } else if (document.querySelector(".media-preview-dialog-mobile") !== null) {
        this.broadcaster.broadcast("hideMediaPreviewDialog");
        console.log("[root.ts] onBacKButton", "hideMediaPreviewDialog");
      } else if (document.querySelector(".vp-log-time-dialog") !== null) {
        this.broadcaster.broadcast("hideLogTimeDialog");
        console.log("[root.ts] onBacKButton", "hideLogTimeDialog");
      } else if (document.querySelector(".vp-timelog-history-dialog") !== null) {
        this.broadcaster.broadcast("hideTimelogHistoryDialog");
        console.log("[root.ts] onBacKButton", "hideTimelogHistoryDialog");
      } else if (document.querySelector(".vp-vnctask-details") !== null) {
        this.broadcaster.broadcast("hideTaskDetails");
        console.log("[root.ts] onBacKButton", "hideTaskDetails");
      } else if (document.querySelector(".vnctask-bulk-edit-dialog") !== null) {
        this.broadcaster.broadcast("hideBulkEditDialog");
        console.log("[root.ts] onBacKButton", "hideBulkEditDialog");
      } else if (document.querySelector("vp-tasks-dialog") !== null) {
        this.broadcaster.broadcast("hideTasksDialog");
        console.log("[root.ts] onBacKButton", "hideTasksDialog");
      } else if (document.querySelector(".vnctask_reminer_dialog") !== null) {
        this.broadcaster.broadcast("hideReminderDialog");
        console.log("[root.ts] onBacKButton", "hideReminderDialog");
      } else if (document.querySelector(".vnctask-compose-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskComposeDialog");
        console.log("[root.ts] onBacKButton", "hideTaskComposeDialog");
      } else if (document.querySelector(".vp-archive-users-dialog") !== null) {
        this.broadcaster.broadcast("hideArchiveUsersDialog");
        console.log("[root.ts] onBacKButton", "hideArchiveUsersDialog");
      } else if (document.querySelector(".vp-invite-users-dialog") !== null) {
        this.broadcaster.broadcast("hideInviteUsersDialog");
        console.log("[root.ts] onBacKButton", "hideInviteUsersDialog");
      } else if (document.querySelector(".vp-users-management-dialog") !== null) {
        this.broadcaster.broadcast("hideUsersManagementDialog");
        console.log("[root.ts] onBacKButton", "hideUsersManagementDialog");
      } else if (document.querySelector(".vp-task-legal-notice-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskLegalNoticeDialog");
        console.log("[root.ts] onBacKButton", "hideTaskLegalNoticeDialog");
      } else if (document.querySelector(".vp-task-service-desk-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskServiceDeskDialog");
        console.log("[root.ts] onBacKButton", "hideTaskServiceDeskDialog");
      } else if (document.querySelector(".vp-task-help-faq-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskHelpFaqDialog");
        console.log("[root.ts] onBacKButton", "hideTaskHelpFaqDialog");
      } else if (document.querySelector(".vp-task-deactivate-confirmation-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAccountDeactivateConfirmation");
        console.log("[root.ts] onBacKButton", "hideTaskAccountDeactivateConfirmation");
      } else if (document.querySelector(".vp-task-deactivate-feedback-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAccountDeactivateFeedback");
        console.log("[root.ts] onBacKButton", "hideTaskAccountDeactivateFeedback");
      } else if (document.querySelector("vp-tfa-settings") !== null) {
        this.broadcaster.broadcast("hideTfaSettings");
        console.log("[root.ts] onBacKButton", "hideTfaSettings");
      } else if (document.querySelector(".vp-task-general-settings-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskGeneralSettingsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskGeneralSettingsDialog");
      } else if (document.querySelector(".vp-task-changelog-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskChangelogDialog");
        console.log("[root.ts] onBacKButton", "hideTaskChangelogDialog");
      } else if (document.querySelector(".vp-task-aboutus-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskAboutusDialog");
        console.log("[root.ts] onBacKButton", "hideTaskAboutusDialog");
      } else if (document.querySelector(".vp-create-list-dialog") !== null) {
        this.broadcaster.broadcast("hideCreateListDialogPage");
        console.log("[root.ts] onBacKButton", "hideCreateListDialogPage");
      } else if (document.querySelector(".vp-folder-option-list-item-dialog") !== null) {
        this.broadcaster.broadcast("hideFolderOptionListItemDialog");
        console.log("[root.ts] onBacKButton", "hideFolderOptionListItemDialog");
      } else if (document.querySelector(".vp-folder-option-list-dialog") !== null) {
        this.broadcaster.broadcast("hideFolderOptionListDialog");
        console.log("[root.ts] onBacKButton", "hideFolderOptionListDialog");
      } else if (document.querySelector(".vp-create-location-dialog") !== null) {
        this.broadcaster.broadcast("hideCreateLocationDialogPage");
        console.log("[root.ts] onBacKButton", "hideCreateLocationDialogPage");
      } else if (document.querySelector(".vp-location-option-list-dialog") !== null) {
        this.broadcaster.broadcast("hideLocationOptionListDialog");
        console.log("[root.ts] onBacKButton", "hideLocationOptionListDialog");
      } else if (document.querySelector(".vp-task-option-list-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskOptionListDialog");
        console.log("[root.ts] onBacKButton", "hideTaskOptionListDialog");
      } else if (document.querySelector(".vp-tag-option-list-dialog") !== null) {
        this.broadcaster.broadcast("hideTagOptionListDialog");
        console.log("[root.ts] onBacKButton", "hideTagOptionListDialog");
      } else if (document.querySelector(".vp-save-search-dialog") !== null) {
        this.broadcaster.broadcast("hideSaveSearchDialog");
        console.log("[root.ts] onBacKButton", "hideSaveSearchDialog");
      } else if (document.querySelector(".vp-apps-dialog") !== null) {
        this.broadcaster.broadcast("hideAppsDialog");
        console.log("[root.ts] onBacKButton", "hideAppsDialog");
      } else if (document.querySelector(".vp-task-settings-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskSettingsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSettingsDialog");
      } else if (document.querySelector(".vp-task-image-cropper-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskImageCropperDialog");
        console.log("[root.ts] onBacKButton", "hideTaskImageCropperDialog");
      } else if (document.querySelector(".vp-task-change-password-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskChangePasswordDialog");
        console.log("[root.ts] onBacKButton", "hideTaskChangePasswordDialog");
      } else if (document.querySelector(".vnc_task_login_profile_dialog") !== null) {
        this.broadcaster.broadcast("hideTaskLoginProfileDialog");
        console.log("[root.ts] onBacKButton", "hideTaskLoginProfileDialog");
      } else if (document.querySelector("vp-select-apps-dialog") !== null) {
        this.broadcaster.broadcast("hideTaskSelectAppsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectAppsDialog");
      } else if (document.querySelector("vp-duedate-range") !== null) {
        this.broadcaster.broadcast("hideTaskDuedateDialog");
        console.log("[root.ts] onBacKButton", "hideTaskDuedateDialog");
      } else if (document.querySelector("vp-search-through") !== null) {
        this.broadcaster.broadcast("hideTaskSearchThroughDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSearchThroughDialog");
      } else if (document.querySelector("vp-select-projects") !== null) {
        this.broadcaster.broadcast("hideTaskSelectProjectsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectProjectsDialog");
      } else if (document.querySelector("vp-select-users") !== null) {
        this.broadcaster.broadcast("hideTaskSelectUsersDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectUsersDialog");
      } else if (document.querySelector("vp-select-tags") !== null) {
        this.broadcaster.broadcast("hideTaskSelectTagsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectTagsDialog");
      } else if (document.querySelector("vp-select-lists") !== null) {
        this.broadcaster.broadcast("hideTaskSelectListsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectListsDialog");
      } else if (document.querySelector("vp-select-locations") !== null) {
        this.broadcaster.broadcast("hideTaskSelectLocationsDialog");
        console.log("[root.ts] onBacKButton", "hideTaskSelectLocationsDialog");
      } else if (document.querySelector("vp-time-range") !== null) {
        this.broadcaster.broadcast("hideTaskTimeRangeDialog");
        console.log("[root.ts] onBacKButton", "hideTaskTimeRangeDialog");
      } else if (document.querySelector(".advance-search-dialog") !== null) {
        this.broadcaster.broadcast("hideAdvanceTaskSearchDialog");
        console.log("[root.ts] onBacKButton", "hideAdvanceTaskSearchDialog");
      } else if (document.querySelector(".vp-mobile-search") !== null) {
        this.broadcaster.broadcast("hideMobileSearchDialog");
        console.log("[root.ts] onBacKButton", "hideMobileSearchDialog");
      }
      console.log("[root.ts] onBackButton", "end");
      this.onPushBugHack();
    }, false);
  }

  ngOnDestroy() {
    this.isAlive = false;

    this.broadcaster.broadcast("appComponentOnDestroy");
  }

  // launchedAppFromLink Event Handler
  onApplicationDidLaunchFromLink (eventData) {
    if (eventData) {
      if (eventData.params) {
        let params = eventData.params;
        switch (params.action) {
          case "confirm-email":
            if (params.token) {
              localStorage.setItem("activation_token", params.token);
              window.postMessage({
                type: "GO_TO_CONFIRM_EMAIL"
              }, "*");
            }
            break;
          case "password-reset":
            if (params.token) {
              localStorage.setItem("password_reset_token", params.token);
              window.postMessage({
                type: "GO_TO_RESET_PASSWORD_PAGE"
              }, "*");
            }
            break;
          case "open-task":
            if (params.id) {
              window.postMessage({
                type: "OPEN_TASK_DETAIL_PAGE",
                id: params.id
              }, "*");
            }
            break;
          case "task-invitation":
            if (params.token) {
              localStorage.setItem("invitation_token", params.token);
              window.postMessage({
                type: "GO_TO_TASK_INVITATION"
              }, "*");
            }
            break;
          case "show-diff":
            if (params.journal_id && params.detail_id && params.id) {
              window.postMessage({
                type: "SHOW_DESCRIPTION_DIFFERENCE",
                id: params.id,
                journal_id: params.journal_id,
                detail_id: params.detail_id
              }, "*");
            }
            break;
        }
      }
    }
  }

  processTriggerActionEvent (eventData) {
    console.log("[AppComponent][processTriggerActionEvent]", eventData);
    if (eventData) {
      if (eventData.params) {
        const params = eventData.params;
        console.log("[AppComponent][processTriggerActionEvent]", params);
        if (params && eventData.path === "/task/open") {
          this.router.navigate([eventData.path], { queryParams: params });
        }
      }
    }
  }

  @HostListener("window:message", ["$event"])
  windowMessageEventHandler(event: MessageEvent) {
    const eventData = event.data;

    if (eventData && eventData.type === TasksConstants.GO_TO_REGISTRATION_PAGE) {
      if (this.isOnline) {
        if (this.configService.get("isUserRegistrationViaVNCd")) {
          this.configService.hideForgotPasswordIframe();
          this.configService.registrationIframe();
        } else {
          this.router.navigate(["/registration/form"], { queryParams: {} });
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    if (eventData && eventData.type === TasksConstants.GO_TO_FORGOT_PASSWORD_PAGE) {
      if (this.isOnline) {
        if (this.configService.get("isForgotPasswordViaVNCd")) {
          this.configService.hideRegistrationIframe();
          this.configService.forgotPasswordIframe();
        } else {
          this.router.navigate(["/forgot-password/form"], { queryParams: {} });
        }
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    if (eventData && eventData.type === TasksConstants.GO_TO_RESET_PASSWORD_PAGE) {
      if (this.isOnline) {
        this.router.navigate(["/forgot-password/reset"], { queryParams: {} });
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    if (eventData && eventData.type === TasksConstants.INTERNET_LOST_CONNECTION) {
      this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
    }

    if (eventData && eventData.type === TasksConstants.CHANGE_SERVER_URL) {
      if (this.isOnline) {
        this.showChangeServerDialog();
      } else {
        this.broadcaster.broadcast("OFFLINE_CONNECTION", true);
      }
    }

    if (eventData && eventData.type === TasksConstants.GO_TO_CONFIRM_EMAIL) {
      let activateToken = localStorage.getItem("activation_token");
      if (activateToken) {
        if (environment.isCordova) {
          setTimeout(() => {
            this.taskService.activateAccount(activateToken).subscribe( res => {
              if ( res && res.includes(TasksConstants.EXPIRED)) {
                const message = this.messageTranslatorService.getMessage(TasksConstants.ACTIVATION_LINK_EXPIRED_MESSAGE);
                this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                });
                this.router.navigate(["/registration/expired"], { queryParams: {} });
              } else {
                this.router.navigate(["/task"], { queryParams: {} });
              }
            });
          }, 200);
        } else {
          setTimeout(() => {
            this.taskService.activateAccount(activateToken).subscribe( res => {
              if ( res && res.includes(TasksConstants.EXPIRED)) {
                const message = this.messageTranslatorService.getMessage(TasksConstants.ACTIVATION_LINK_EXPIRED_MESSAGE);
                this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                });
                this.router.navigate(["/registration/expired"], { queryParams: {} });
              } else {
                this.router.navigate(["/task"], { queryParams: {} });
              }
            });
          } , 2000);
        }
      }
    }

    if (eventData && eventData.type === TasksConstants.OPEN_TASK_DETAIL_PAGE) {
      let token = localStorage.getItem("token");
      if (token) {
        this.router.navigate(["/task/all/detail/" + eventData.id ], { queryParams: {} });
      } else {
        localStorage.setItem("open-task-id", eventData.id);
        this.router.navigate(["/task"], { queryParams: {} });
      }
    }

    if (eventData && eventData.type === TasksConstants.GO_TO_TASK_INVITATION) {
      let token = localStorage.getItem("token");
      let invitationToken = localStorage.getItem("invitation_token");
      if (token && invitationToken) {
        this.taskService.acceptInvitation(invitationToken).subscribe( res => {
          localStorage.removeItem("invitation_token");
          if ( res && res._body.includes(TasksConstants.EXPIRED)) {
            const message = this.messageTranslatorService.getMessage(TasksConstants.TOKEN_EXPIRED);
            this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
            });
          } else {
            this.router.navigate(["/task"], { queryParams: {} });
          }
        });
      } else {
        this.router.navigate(["/task"], { queryParams: {} });
      }
    }

    if (eventData && eventData.type === TasksConstants.SHOW_DESCRIPTION_DIFFERENCE) {
      let token = localStorage.getItem("token");
      localStorage.setItem("desc_journal_id", eventData.journal_id);
      localStorage.setItem("desc_detail_id", eventData.detail_id);
      if (token) {
        this.router.navigate(["/task/all/detail/" + eventData.id ], { queryParams: { show_desc_diff: true } });
      } else {
        localStorage.setItem("open-task-id", eventData.id);
        this.router.navigate(["/task"], { queryParams: {} });
      }
    }
  }

  showChangeServerDialog() {
    const dlg = this.matDialog.open(ChangeServerDialogComponent, {
      maxHeight: "100%",
      maxWidth: "100%",
      autoFocus: false,
    });
    this.changeDetectionRef.detectChanges();
  }

  private onPushBugHack() {
    setTimeout(() => {
    this.changeDetectionRef.detectChanges();
    }, 100);
  }

  @HostListener("document:click", ["$event"])
  documentClickEventHandler(event: any) {
    if (!this.isCordovaOrElectron) {
      return;
    }
    let url: string;
    if (event.target.classList.contains("open-new-window")) {
      url = event.target.href;
    }

    this.actionWheelMenuService.hideCircularMenu();
    if (url) {
      event.stopPropagation();
      event.preventDefault();
      console.log("[documentClickEventHandler]", event);
      if (this.electronService.isElectron) {
        this.electronService.openExternalUrl(url);
      } else if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    }
  }

  private handleSharing() {
    let timestamp = new Date().getTime();
    this.router.navigate(["/task/open"], { queryParams: { action: "compose", description: "", timestamp: timestamp} });
  }

  handleActionClick(v) {
    const jid = v.data;
    const action = v.action;
    switch (action) {
      case "one" : this.startTalkAudioCall(jid); break;
      case "two" : this.startTalkChat(jid); break;
      case "three" : this.startTalkVideoCall(jid); break;
      case "four" : this.sendEmail(jid); break;
      case "five" : this.createTicket(jid); break;
      case "six" : this.createTask(jid); break;
      case "info" : this.openProfile(jid); break;
      default: break;
    }
  }

  startTalkAudioCall(email) {
    if (email === this.user?.email) {
        // this.toastService.show("CAN_NOT_CALL_YOURSELF");
        const message = this.messageTranslatorService.getMessage("CAN_NOT_CALL_YOURSELF");
        this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
        });
        this.changeDetectionRef.markForCheck();
        return;
    }
    this.taskRepository.makeTalkAudioChatVideoOperation(email, "audio-call", "group-audio");
  }

  startTalkVideoCall(email) {
      if (email === this.user?.email) {
          // this.toastService.show("CAN_NOT_CALL_YOURSELF");
          const message = this.messageTranslatorService.getMessage("CAN_NOT_CALL_YOURSELF");
          this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
          return;
      }
      this.taskRepository.makeTalkAudioChatVideoOperation(email, "video-call", "group-video");
  }

  startTalkChat(email) {
      if (email === this.user?.email) {
          // this.toastService.show("CAN_NOT_CHAT_YOURSELF");
          const message = this.messageTranslatorService.getMessage("CAN_NOT_CHAT_YOURSELF");
          this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
          });
          return;
      }
      this.taskRepository.makeTalkAudioChatVideoOperation(email, "start-chat", "group-chat");
  }

  sendEmail(email) {
      console.log("[sendEmail]::", email);
      this.taskRepository.sendEmail(email);
  }

  openProfile(email) {
    this.store.dispatch(new SetActiveProfile(email));
    this.store.dispatch(new SetRightSidebarStatus(true));
  }

  createTicket(email) {
      if (email === this.user?.email) {
          this.taskService.getLoggedInUserContactInfo(email).pipe(take(1)).subscribe(res => {
              if (res.products) {
                  let projectApp = res.products.filter( ap => ap.name.toLowerCase() === "vncproject");
                  if (projectApp.length > 0) {
                      this.taskRepository.createTicket(res.username);
                  } else {
                      // this.toastService.show("NO_ACCESS_FOR_APP");
                      const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                      });
                  }
              } else {
                  // this.toastService.show("NO_ACCESS_FOR_APP");
                  const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                  this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                  });
              }
          });
      } else {
          this.taskService.getContactInfo(email).pipe(take(1)).subscribe(res => {
              if (res.products) {
                  let projectApp = res.products.filter( ap => ap.name.toLowerCase() === "vncproject");
                  if (projectApp.length > 0) {
                      this.taskRepository.createTicket(res.username);
                  } else {
                      // this.toastService.show("NO_ACCESS_FOR_APP");
                      const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                      this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                      });
                  }
              } else {
                  // this.toastService.show("NO_ACCESS_FOR_APP");
                  const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                  this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                  });
              }
          });
      }
  }

  openCreateTaskDialog(username) {
    this.store.dispatch(new SetEditTaskView(false));
    this.matDialog.open(TaskComposeComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "task-compose-dialog-new",
      data: { assignee: username }
    });
    this.changeDetectionRef.markForCheck();

  }

  createTask(email) {
    if (email === this.user?.email) {
        this.taskService.getLoggedInUserContactInfo(email).pipe(take(1)).subscribe(res => {
            if (res.products) {
                let taskApp = res.products.filter( ap => ap.name.toLowerCase() === "vnctask");
                if (taskApp.length > 0) {
                    let userName = res?.username;
                    if (userName) {
                      this.openCreateTaskDialog(userName);
                    }
                    else {
                      this.openCreateTaskDialog(res?.email.split("@")[0]);
                    }
                } else {
                    // this.toastService.show("NO_ACCESS_FOR_APP");

                    const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                    this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                    });
                }
            } else {
                // this.toastService.show("NO_ACCESS_FOR_APP");
                const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
              });
            }
        });
    } else {
        this.taskService.getContactInfo(email).pipe(take(1)).subscribe(res => {
            if (res.products) {
                let taskApp = res.products.filter( ap => ap.name.toLowerCase() === "vnctask");
                if (taskApp.length > 0) {
                  let userName = res?.username;
                  if (userName) {
                    this.openCreateTaskDialog(userName);
                  }
                  else {
                    this.openCreateTaskDialog(res?.email.split("@")[0]);
                  }
                } else {
                    // this.toastService.show("NO_ACCESS_FOR_APP");
                    const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                    this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                    });
                }
            } else {
                // this.toastService.show("NO_ACCESS_FOR_APP");
                const message = this.messageTranslatorService.getMessage("NO_ACCESS_FOR_APP");
                this.vncLibaryService.openSnackBar(message, "","", "", 3000, "bottom", "left").subscribe(res => {
                });
            }
        });
    }
}
}
