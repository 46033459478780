
<!--
  ~ VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <cdk-virtual-scroll-viewport appendOnly itemSize="56" class="cdk-task-list" (scroll)="scrollMoreContacts($event)">
    <div *cdkVirtualFor="let task of tasks">
      <vp-vnctask-list-item [task]="task" (onTaskClick)="onTaskClicked($event)" (onTaskSelect)= "onTaskSelected($event)"  ></vp-vnctask-list-item>
    </div>
  </cdk-virtual-scroll-viewport>
<div *ngIf="!tasks || (tasks && !tasks.length) && !isSearchMode" class="empty-wrapper">
 <div class="notask-container"><mat-icon>event_available</mat-icon></div>
 <div>{{ 'NO_TASK' | translate }}</div>
 <div *ngIf="isMobileDevice" class="add-a-task" (click)="addTaskClick()">{{ 'ADD_A_TASK' | translate }}</div>
</div>
